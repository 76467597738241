
@import "sass/site/_colours.scss";

.results-status {
  border: none;
}

.form-control.has-error{
  border-color: $validation-error-color;
  &:focus {
    border-color: $validation-error-color;
    box-shadow: $input-error-box-shadow;
  }
}

.error-text {
    color: $validation-error-color;
    margin-bottom: 0px;
}
