
    @import "sass/site/_colours.scss";
    @import "sass/site/_modal-sizes.scss";
    @import "sass/site/_forms.scss";
    @import "sass/site/_variables.scss";
    @import "sass/site/_empty-state.scss";

    .uploads-list-container {
        min-height: $edit-metadata-main-control-min-height;
        max-height: $modal-dialog-main-control-max-height;
        overflow-y: auto;
        overflow-x: hidden;
        border: 1px solid $background-light-grey;
    }
    .config-fields-container {
        min-height: $edit-metadata-main-control-min-height;
        max-height: $modal-dialog-main-control-max-height;
        overflow-y: auto;
        padding: 0 10px 10px 10px;
    }
    .config-fields-title {
      margin-top: 0px;
    }
    .label-for-selector {
        margin-left: 5px;
    }
    .title-container {
        margin-bottom: 10px;
    }
    .right-block{
      padding-left: 0px;
    }
    .no-configurable-fields-disclaimer-container{
      color: $validation-error-color;
    }
