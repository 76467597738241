
    @import "sass/site/_colours.scss";

    .item-container {
        border-bottom: 1px solid $background-light-grey;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
    }
    .item-container:before {
      content: '';
      width: 4px;
      height: 40px;
      background: white;
    }
    .item-container.smaller:before {
      height: 30px;
    }
    .item-container:hover::before {
      background: $hover-list-item-color;
    }
    .item-container.selected {
      background-color: $selected-list-item-background-color;
    }
    .item-container.selected:before {
      background: $selected-list-item-color;
    }
    .validation-icon {
      margin-right: 5px;
      margin-left: 5px;
      color: red;
    }
    .item-label {
      display: flex;
      flex: auto;
      margin-left: 5px;
      margin-bottom: 0px;
      font-weight: 100;
    }
    .name-container {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
    .item-checkbox {
      margin: 0;
    }
