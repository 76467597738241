
@import "sass/site/_colours.scss";

.request-header-disclaimer {
  border: 1px solid transparent;
  color: $alert-warning-text-color;
  background-color: $alert-warning-background-color;
  border-color: $alert-warning-border-color;
  border-radius: 5px;
  padding: 10px;
}
.first-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.main-label {
  flex: auto;
  text-align: center;
  margin: 0;
  cursor: pointer;
}
.expand-icon{
  cursor: pointer;
}
.options-container{
  text-align: center;
}
.list-container{
  display: inline-block;
  text-align: left;
  margin-bottom: 0;
  margin-top: 5px;
}
