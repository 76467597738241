
@import "sass/site/_colours.scss";

.disclaimer-container {
    color: $alert-warning-text-color;
    font-size: 12px;
}
.error-text {
    color: $validation-error-color;
    margin-bottom: 0px;
}
