
@import "sass/site/_colours.scss";
@import "sass/site/_modal-sizes.scss";

    .table-data {
        max-height: $modal-dialog-main-control-common-height;
        overflow-y: auto;
        overflow-x: hidden;

        &--row:nth-child(even) {
            background-color: $background-light-grey;
        }
    }
