
select {
  display: inline;
  width: fit-content;
  margin-left: 0.5em;
  font-weight: normal;
}
.btn-sort-order {
  margin-top: -4px;
}
.sort-control-container {
  padding-bottom: 5px;
}
