
 .dropdown-btn-sm-default {
   padding-left: 25px;
 }
 .display-inline {
   display: inline;
 }
 .select-all-checkbox {
   position: absolute;
   margin-top: 0.65em;
   margin-left: 10px;
   z-index: 1;

   &.marked {
     opacity: .6;
   }
 }
