
.table {
  border: solid 1px lightgray;
  margin-bottom: 0;
}
td {
  max-width: 100px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
