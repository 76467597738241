
@import "../../../../../Sass/site/colours";

.discussion-messaging__no-messages {
  margin-top: 40px;
}

.form-control.disabled{
  opacity: .5;
  background-color: #fff;
}

.form-control.has-error{
  border-color: $validation-error-color;
  &:focus {
    border-color: $validation-error-color;
    box-shadow: $input-error-box-shadow;
  }
}

.error-text {
  color: $validation-error-color;
  margin-bottom: 0px;
}
