
@import "sass/site/_colours.scss";
.filter-input {
  background-color: $background-light-grey;
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid $input-border-color;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  border-radius: initial;

  &:enabled:focus {
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
    border-radius: initial;
  }
}
