
    .sticky {
        position: sticky;
        z-index: 11;
    }

    .report-button__container {
        border-right: none;
        margin-right: 0;
        padding-right: 0;
    }
