
@import "sass/site/_colours.scss";

    .new-discussion-message__input {
        background-color: $background-light-grey;
    }

    .p-inputtext:enabled:focus {
        box-shadow: $input-focus-box-shadow;
    }
