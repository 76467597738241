﻿label.required:after {
  content: " *";
  color: #F00;
}

h3.required:after,
h4.required:after {
    content: " *";
    color: #F00;
}

// Prevent textarea inputs from being resized
// TODO: Check if this should be considered and override by looking at the BS styles for textarea
textarea {
    resize: none;
}

.validation-summary {
    color: $validation-error-color;
}

// Styling for input type file upload / browser button styling
.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

// Use standard background colour and borders on input controls. Used on the reset password screens 
// where grey backgrounds on inputs looks wrong.
input.form-control-standard {
    background-color: #FFF;
    border-color: #CCC;
    border-radius: 4px;
}

div.input-group-addon-standard {
    border-color: #CCC
}

.form-control-readonly { 
    display: block;
    width: 100%;
    height: auto;
    min-height: 34px;
    padding: 3px;
    line-height: 24px;
    word-wrap:break-word;
}

.dynamic-form-root-element-readonly-viewashtml {
    div[ref~="input"] {
        white-space: pre-wrap;
    }
}

textarea.form-control.is-invalid:focus {
    box-shadow: $input-error-box-shadow;
}