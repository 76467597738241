
@import "sass/site/_colours.scss";
@import "sass/site/_forms.scss";

.warning-text {
  color: $alert-warning-text-color;
  margin-top: 0px;
  margin-bottom: 0px;
}
.error-text {
    color: $validation-error-color;
    margin-bottom: 0px;
}
.disclaimer {
  margin-bottom: 0px;
}

small {
    word-wrap: break-word;
}
