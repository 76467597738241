
.dual-listbox-filter-title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.p-picklist-item {
  .icons-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 3px;
  }

  .item-checkbox {
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
  }

  .icon-tooltip {
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
  }
}
